import React from "react";
import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { GridContainer, GridItem } from "Components/Grid";

interface Props {
  steps: {
    label: string;
    component: React.ReactElement;
    completed?: boolean;
  }[];
  updateSteps: (step: number, { completed }: { completed: boolean }) => void;
  handleOnClose?: () => {};
}

export const StepForm: React.FC<Props> = ({
  steps,
  updateSteps,
  handleOnClose,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNextFunction = (next: () => boolean) => {
    return async () => {
      const success = await next();

      if (success && activeStep < steps.length - 1) {
        goToNextStep();
      }
    };
  };

  const goToNextStep = () => {
    if (activeStep + 1 <= steps.length - 1) {
      updateSteps(activeStep, { completed: true });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleDone = () => {
    handleOnClose();
  };

  return (
    <Box>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map(({ label, completed }) => (
          <Step key={label} completed={completed}>
            <StepLabel color="inherent">{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {React.cloneElement(steps[activeStep].component, {
          nextStep: goToNextStep,
          done: handleDone,
          ModalFooter: ({ onNext, justifyContent, disabled, columnSize }) => (
            <GridContainer justifyContent={justifyContent}>
              <GridItem columnSize={columnSize ?? 6}>
                <Button
                  onClick={handleNextFunction(onNext)}
                  fullWidth
                  disabled={disabled}
                >
                  {activeStep === steps.length - 1 ? "CREAR" : "SIGUIENTE"}
                </Button>
              </GridItem>
            </GridContainer>
          ),
        })}
      </div>
    </Box>
  );
};
