import React from "react";
import { SoftPhone } from "Components/SipClient/SoftPhone/SoftPhone";
import JsSIP from "jssip";
import audioPlayer from "Components/SipClient/audioPlayer";
import { SIP_SOCKET, SIP_URL, WS_SIP_SOCKET } from "constanst";

export const MEDIA_CONSTRAINTS = {
  audio: true,
  video: false,
};

export const JsSIPPhone = ({ extension, password, displayname }) => {
  // JsSIP.debug.enable("JsSIP:*");
  JsSIP.debug.disable();
  const [session, setSession] = React.useState<any>();
  const [incomingCall, setIncomingCall] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false);
  const [posteExtension, setPosteExtension] = React.useState("");

  const phone = React.useMemo(() => {
    const wssSocket = new JsSIP.WebSocketInterface(SIP_SOCKET);
    // const wsSocket = new JsSIP.WebSocketInterface(WS_SIP_SOCKET);

    wssSocket.via_transport = "auto";
    // wsSocket.via_transport = "auto";

    return new JsSIP.UA({
      sockets: [wssSocket],
      uri: `sip:${extension}@${SIP_URL}`,
      password,
      authorization_user: extension,
    });
  }, [displayname, extension, password]);

  let remoteStream;

  const remoteAudio = React.useMemo(() => new window.Audio(), []);

  const completeSession = () => {
    setSession(null);
    setIncomingCall(false);
    setPosteExtension("");
    audioPlayer.stop("ringing");
  };

  React.useEffect(() => {
    if (phone) {
      remoteAudio.autoplay = true;
      remoteAudio.crossOrigin = "anonymous";

      phone.on("registered", () => {
        setIsRegistered(true);
      });

      phone.on("registrationFailed", (event) => {
        console.error("registrationFailed", event);
      });

      phone.on("newMessage", function (event) {
        console.log("newMessage", event);
      });

      phone.on("unregistered", (event) => {
        console.error("unregistered", event);
      });

      phone.on("newRTCSession", (event) => {
        if (session && session.isInProgress()) {
          session.terminate({
            status_code: 486,
            reason_phrase: "Busy Here",
          });
          return;
        }

        setSession(event.session);
      });

      phone.start();
    }

    return () => {
      phone.stop();
    };
  }, [phone]);

  React.useEffect(() => {
    if (session) {
      session.on("ended", () => {
        completeSession();
      });

      session.on("failed", (e) => {
        console.log("failedInncol", e);
        completeSession();
      });

      session.on("confirmed", () => {
        audioPlayer.stop("ringing");
        const localStream = session.connection.getLocalStreams()[0];
        const dtmfSender = session.connection.createDTMFSender(
          localStream.getAudioTracks()[0]
        );

        session.sendDTMF = (tone: any) => {
          dtmfSender.insertDTMF(tone);
        };
      });

      session.on("peerconnection", (event) => {
        event.peerconnection.onaddstream = (stream) => {
          remoteAudio.srcObject = stream.stream;
          remoteAudio.play();
        };

        //@ts-ignore eslint-disable-next-line
        remoteStream = new MediaStream();
        event.peerconnection.getReceivers().forEach((receiver) => {
          remoteStream.addTrack(receiver.track);
        });
      });

      if (session.direction === "incoming") {
        setIncomingCall(true);
        setPosteExtension(session.remote_identity.uri.user);
        audioPlayer.play("ringing");
      } else {
        session.connection.addEventListener("addstream", (stream) => {
          remoteAudio.srcObject = stream.stream;
        });
      }
    }
  }, [session]);

  const answer = () => {
    session?.answer({
      mediaConstraints: MEDIA_CONSTRAINTS,
    });
  };

  const hangUp = () => {
    session?.terminate?.();
  };

  const reject = () => {
    session?.reject?.();
  };

  const call = () => {};

  return (
    <SoftPhone
      isComing={incomingCall}
      answer={answer}
      displayName={posteExtension}
      hangUp={hangUp}
      isRegistered={isRegistered}
      reject={reject}
      call={call}
    />
  );
};
