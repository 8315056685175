import React from "react";
import { Chip, styled, Typography } from "@mui/material";
import { GridContainer, GridItem } from "../Grid";
import { UserFormInitialState } from "./constants";
import { TextField as UnstyledTextField } from "Components/Shared/TextField";
import useMutationHook from "@hooks/useMutation";

interface Props {
  ModalFooter?: React.JSXElementConstructor<any>;
  nextStep?: () => void;
}

interface CreateUserProps {
  name: string;
  lastname: string;
  email: string;
  username: string;
  password: string;
  role: string;
  phone: string;
  dob: string;
}

interface CreateUserData {
  userId: string;
  access_token: string;
}

const TextField = styled(UnstyledTextField)`
  margin-bottom: 1rem;
`;

const UserForm: React.FC<Props> = ({ ModalFooter, nextStep }) => {
  const [form, setForm] = React.useState(UserFormInitialState);
  const { mutateAsync: createUser } = useMutationHook<
    CreateUserData,
    CreateUserProps
  >("user", "createUser");

  const handleOnChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ currentTarget: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (newValue: string) => {
    setForm((prev) => ({ ...prev, role: newValue }));
  };

  return (
    <>
      <GridContainer noPadding>
        <GridItem columnSize={6} style={{ padding: "2.5rem 1rem" }}>
          <>
            <TextField
              name="name"
              placeholder="Nombre"
              label="Nombre"
              fullWidth
              value={form.name}
              onChange={handleOnChange}
              validate={/^[a-zA-Z ]+$/}
              errorMessage="El campo Nombre es requerido"
            />
            <TextField
              name="lastname"
              placeholder="Apellido"
              label="Apellido"
              fullWidth
              value={form.lastname}
              onChange={handleOnChange}
              validate={/^[a-zA-Z ]+$/}
              errorMessage="El campo Apellido es requerido"
            />
            <TextField
              name="username"
              placeholder="Nombre de usuario"
              label="Usuario"
              fullWidth
              value={form.username}
              onChange={handleOnChange}
              validate={/^[a-zA-Z]+$/}
              errorMessage="El campo Nombre de usuario es requerido"
            />
            <TextField
              name="email"
              placeholder="Correo"
              label="Correo electronico"
              type="email"
              fullWidth
              value={form.email}
              onChange={handleOnChange}
              validate={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
              errorMessage="Debe ser un correo válido"
            />
            <TextField
              name="phone"
              placeholder="Teléfono"
              label="Teléfono"
              type="number"
              fullWidth
              value={form.phone}
              onChange={handleOnChange}
              validate={/^\d{10}$/}
              errorMessage="Debe ser un teléfono válido de 10 caracteres"
            />
          </>
        </GridItem>
        <GridItem columnSize={6} style={{ padding: "2.5rem 0 2.5rem 1rem" }}>
          <>
            <TextField
              name="dob"
              placeholder="Fecha de nacimiento"
              type="date"
              label="Feca de nacimiento"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={form.dob}
              onChange={handleOnChange}
              validate={
                /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
              }
              errorMessage="Debe ser una fecha válida"
            />
            <GridItem fullWidth style={{ lineHeight: "1rem" }}>
              <Typography variant="caption">Rol</Typography>
            </GridItem>
            <GridContainer noPadding>
              {[
                { label: "Admin", value: "ADMIN" },
                { label: "Monitoreo", value: "MONITORING" },
                { label: "Operador", value: "OPERATOR" },
                { label: "Movil", value: "MOBILE" },
              ].map(({ label, value }) => (
                <GridItem key={label} columnSize={6}>
                  <Chip
                    label={label}
                    color="secondary"
                    variant={form.role === value ? "filled" : "outlined"}
                    onClick={() => handleRoleChange(value)}
                    style={{
                      width: "100%",
                    }}
                  />
                </GridItem>
              ))}
            </GridContainer>
            <TextField
              name="password"
              placeholder="Contraseña"
              type="password"
              label="password"
              fullWidth
              value={form.password}
              onChange={handleOnChange}
              validate={/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!?\*]).{8,}$/}
              errorMessage="Debe contener 8 caracteres, mínimo una letra y un número"
              style={{ marginTop: "0.75rem" }}
            />
          </>
        </GridItem>
      </GridContainer>
      <ModalFooter
        onNext={async () => {
          const { ...userData } = form;
          const user = await createUser(userData);

          if (!user || !user.userId) {
            return false;
          }

          localStorage.setItem("userId", user.userId);

          return true;
        }}
        justifyContent="end"
        disabled={Object.values(form).some((value) => !value)}
      />
    </>
  );
};

export default UserForm;
