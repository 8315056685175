import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { NotificationItems } from "./notification-items";
import { useNotification } from "@hooks/notifications";

export type NotificationType =
  | "Llamada Entrante"
  | "Impacto"
  | "Poste abierto"
  | "Batería baja";

export interface Notification {
  assignBy?: string;
  callRecording?: string;
  createdAt: string;
  dateTime: string;
  description: string;
  poste: {
    _id: string;
    name: string;
  };
  type: "SENSOR" | "Llamada";
  updatedAt: string;
  _id: string;
  read: boolean;
}

interface Props {
  closeNotifications: () => void;
}

export const Notifications: React.FC<Props> = ({ closeNotifications }) => {
  const { notifications, setRead } = useNotification();

  React.useEffect(() => {
    return () => {
      notifications
        .filter((not) => !not.read)
        .forEach((not) => {
          setRead(not._id);
        });
    };
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Card sx={{ height: "100%" }}>
        <CardHeader
          action={
            <IconButton onClick={closeNotifications}>
              <Close />
            </IconButton>
          }
          title="Notificaciones"
        />
        <Divider />
        <CardContent sx={{ padding: 0, height: "90%", overflow: "scroll" }}>
          <NotificationItems />
          <Button size="large" fullWidth>
            Cargar Más
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};
