const prod = "https://sos-api.3ri.mx/api/v1";
// const dev = "http://192.168.1.15:3001/api/v1";
// const dev = "http://monitoring-api:3001/api/v1";
// const socket_dev = "ws://monitoring-api:3001";
export const API_URL = 'https://api.amisos.mx/api/v1'
export const SIP_URL = "siptest.online";
export const SIP_SOCKET = "wss://siptest.online:8089/ws";
export const SOCKET_URL = 'wss://api.amisos.mx' // "wss://sos-api.3ri.mx";
export const PUBLIC_GCP_KEY = "AIzaSyBV67k22aupTZwOmOkYSj8lN-clWqEYQsQ";
export const WS_SIP_SOCKET = "ws://siptest.online:8088/ws";
export const MEDIA_CONSTRAINTS = {
  audio: true,
  video: false,
};

export const DRAWER_WIDTH = 200;
