import React from "react";
import { Map } from "./../Map/map";
import useFetchQuery from "@hooks/fetchData";
import { LeafletMap } from "Components/Map/leaflet";

export const Monitoreo = () => {
  const { data } = useFetchQuery('poste', 'mapPostes');

  const postesMarkers = React.useMemo(() => data?.postes ?? [], [data]);

  return <LeafletMap markers={postesMarkers} />
  /*
  return (
    <Map markers={postesMarkers} />
  );
  */
};
