import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Notifications as NotificationIcon } from "@mui/icons-material";
import { Notifications } from "Components/Monitoring/notifications";
import { useAuth } from "@hooks/Auth";
import { JsSIPPhone } from "Components/JsSIPPhone";
import { useNotification } from "@hooks/notifications";

export const MonitoringAppBar = ({ handleDrawerToggle }) => {
  const { extension } = useAuth();
  const { count } = useNotification();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isNotificationOpen, setNotificationOpen] =
    React.useState<boolean>(false);

  const handleNotificationToggle = () => {
    setNotificationOpen((state) => !state);
  };

  const drawer = React.useMemo(
    () => (
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={isNotificationOpen}
        onClose={handleNotificationToggle}
      >
        <Notifications closeNotifications={handleNotificationToggle} />
      </Drawer>
    ),
    [isMobile, isNotificationOpen, handleNotificationToggle]
  );

  return (
    <>
      <AppBar color="inherit">
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Monitoreo
          </Typography>
          {extension && extension.ext && (
            <JsSIPPhone
              extension={extension.ext}
              password={extension.password}
              displayname={extension.displayname}
            />
          )}
          <IconButton
            size="large"
            aria-label="Notificaciones"
            onClick={handleNotificationToggle}
            color="inherit"
          >
            <Badge badgeContent={count} color="primary">
              <NotificationIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      {drawer}
    </>
  );
};
