import React from "react";
import { expiresAt } from "utils";
import usePost from "@hooks/useFetch/usePost";
import { useSnackbar } from "@hooks/SnackBar";

interface AuthContextType {
  user: {
    id: string;
    name: string;
    lastname: string;
    role: string;
    email: string;
    dob: string;
    username: string;
    phone: string;
  };
  extension: {
    id: string;
    ext: string;
    password: string;
    displayname: string;
  };
  subscriptions: {
    id: string;
    calls: boolean;
    notifications: boolean;
  };
  signin: (user: string, password: string) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<any>(null);
  const [extension, setExtension] = React.useState<any>(null);
  const [subscriptions, setSubscriptions] = React.useState<any>(null);
  const [signIn, { data }] = usePost<any>('auth/signin')
  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    if (data?.message) {
      openSnackBar(data?.message, 'error')
      return;
    }

    if (data) {
      // @ts-ignore
      const { user, extension, subscription, access_token, refresh_token } =
        data;

      if (extension) {
        setExtension(extension);
        localStorage.setItem("_extension", JSON.stringify(extension));
      }

      if (subscription) {
        setSubscriptions(subscription);
        localStorage.setItem("_subscriptions", JSON.stringify(subscription));
      }

      setUser(user);

      localStorage.setItem("_user", JSON.stringify(user));
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("_refresh-token", refresh_token);
      localStorage.setItem("expiresAt", expiresAt().toLocaleString());

      window.location.href = "/";
    }
  }, [data]);

  const signin = async (username: string, password: string) => {
    signIn({ username, password });
  };

  const signout = (callback: VoidFunction) => {
    localStorage.clear();

    setUser(null);
    setExtension(null);
    setSubscriptions(null);

    callback();
  };

  React.useLayoutEffect(() => {
    const token = localStorage.getItem("access_token");
    const user = localStorage.getItem("_user");
    const subscriptions = localStorage.getItem("_subscriptions");
    const extension = localStorage.getItem("_extension");

    if (user && token) {
      setUser(JSON.parse(user));
      setSubscriptions(JSON.parse(subscriptions));
      setExtension(JSON.parse(extension));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, subscriptions, extension, signin, signout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
